import React, { useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import NewexternaSignInStyles from "../signIn/newSignInFormStyle";
import CarrierLogo from "../../../assets/images/carrier_header.png";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import CommonHeader from '../common/commonHeader';

function ExternalSignIn(props) {
    const {signInStatus,invalidUserError,validateError,login_id,password,signupComponent,
        cardContent_Password,forgotDiv,formfield,button_SignIn,div_Cancel,div_SignIn,button_Cancel,span_TextSignin,span_TextCancel,cardContent_externalLogin,cardContent_Button_External} = NewexternaSignInStyles();
 
    return (
        <React.Fragment>
        {props.showExternalSignInForm && 
            <CommonHeader header="Login" customTemplate={props.customTemplate}>
                {( props.signInError && !props.signinErrorToDefault) &&
                <span className={invalidUserError}>
                    {props.signInError}
                </span>
                }
                {(props.signInStatus && !props.signInStatusToDefault)&&
                <span className={signInStatus}>
                {props.signInStatus}
                </span>
                }
                {(props.signInUnReq && !props.UserNameErrorToDefault)&&
                <span className={validateError}>
                    {props.signInUnReq}
                </span>
                }
                <div>
                    <TextField
                        name="username"
                        className={signupComponent}
                        label="email"
                        variant="filled"
                        size="small"
                        autoFocus
                        autoComplete='off'
                        onChange={props.onChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                props.onEnter(e)
                            }
                        }}
                    />
                </div>
                <div style={{"margin-top":"16px"}}>
                    {(props.signInPwdReq && !props.passwordErrorFieldToDefault)&&
                    <span className={validateError}>
                        {props.signInPwdReq}
                    </span>
                    }
                
                    <TextField
                        name="password"
                        className={signupComponent}
                        id="umPassword"
                        label="password"
                        type="password"
                        variant="filled"
                        size="small"
                        onChange={props.onChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                props.onEnter(e)
                            }
                        }}
                    />
                </div>                    
                <div className={forgotDiv}>
                    <a href="#" className="forgotPassword"
                        onClick={props.ForgotPassword}> Forgot password? 
                    </a>
                </div>
                <div className={formfield}>
                <div className={div_SignIn}>
                        <button type="button" className="btnExtSignIn" disabled={props.signInStatus.indexOf("wait") !== -1? `disabled`: ``} onClick={props.signIn}>
                            <span className="btnLabel">
                                Sign In
                            </span>
                        </button>
                    </div> 
                    <div className={div_Cancel}>
                        <button type="button" className="buttonUM btnCancel" onClick={props.closeExternalLogin}>
                            <span className="btnLabel">
                                Cancel
                            </span>
                        </button>
                    </div>       
                </div>
            </CommonHeader>
        }
        </React.Fragment>
    );
}

export default ExternalSignIn;