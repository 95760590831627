import { makeStyles } from "@material-ui/core/styles";
import BGImage from "../../../assets/images/BG.png";

const CSSsignInStyles = makeStyles((theme) => ({
    rootsignIn: {
        "margin": "0",
        "font-size": "1rem",
        "font-weight": "400",
        "line-height": "1.5",
        "color": "#212529",
        "text-align": "center",
        "background-color": "#fff",
        "background-size": "cover",
        "background-image": `url(${BGImage})`,
        "background-repeat": "no-repeat",
        "background-attachment": "fixed",
        "background-color": "#f0f0f0",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif"
    },
    navbarbrandlogo: {
        height: "40px",
        margin: "10px",
        marginLeft: "-40px",
        verticalAlign:"middle",
        borderStyle:"none"
    },
    button: {
        "margin-bottom": "1rem",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        "color": "#003DCC",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        "background": "#FFF",
        padding: "0",
        "text-transform": "none",
        height: "32px",
        padding: "10px",
        width: "100%",
        "box-shadow": "none;",
        "&:hover": {
            "text-decoration": "underline",
            background: "#FFF;"
        },
    },
    externalcarriersignintext: {
        "font-size": "18px",
        color: "#000000",
        "font-weight": "bold",
        "margin-bottom": "10px",
        "margin-top": "5px",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif;"
    },
}));

export default CSSsignInStyles;