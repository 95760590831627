import appConfig from "../environment/config";

const awsmobile = {
    "aws_project_region": appConfig.awsProjectRegion, //"us-east-2",
    "aws_cognito_identity_pool_id": appConfig.awsCognitoIdentityPoolId,//"us-east-2:6b011565-0372-4f2e-9327-53a5448cd4ae",//"us-east-2:d134dd1a-560b-46cf-a917-c9aae0684eea",//
    "aws_cognito_region": appConfig.awsCognitoRegion, //"us-east-2",
    "aws_user_pools_id": appConfig.awsUserPoolsId,//"us-east-2_0ozfAABUx",//"us-east-2_rjmskKvmB", //
    "aws_user_pools_web_client_id": appConfig.awsUserPoolsWebClientId,//"3m3veigetlankmcq0n35nebs0j",//"4pf6qvsk8b2rd9n1raj22ka18b", //
    "oauth": {
        "domain": appConfig.awsCognitoDomain, //"carrierappshub.auth.us-east-2.amazoncognito.com",//"carrierum.auth.us-east-2.amazoncognito.com", //
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": `${window.location.origin}/`,//"https://login.microsoftonline.com/36839a65-7f3f-4bac-9ea4-f571f10a9a03/oauth2/authorize",//"https://dev.d3izksau609npo.amplifyapp.com/",
        "redirectSignOut": `${window.location.origin}/`,
        "SignOutURI": `${window.location.origin}/`,//"https://carrierum.auth.us-east-2.amazoncognito.com.auth0.com/v2/logout",
        "responseType": "code"
    },
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "authenticationFlowType": 'USER_PASSWORD_AUTH'
};

export default awsmobile;