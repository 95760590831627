import React from "react";
import logoFormStyles from "../brandLogo/logoFormStyle";
import signInStyles from "../signIn/signInFormStyle"
import CSSLogo from "../../../assets/images/CSS-logo.png";
import CarrierWhiteLogo from "../../../assets/images/Carrier-logo.png";
import {AppType} from "../../../constant/appType";

function LogoForm(props) {
    const { CSSbrand } = logoFormStyles();
    const { brand,navbarbrandlogo} = signInStyles();

    return (
        <>
        {props.appConfig.appType === AppType.CSS ?
            <div className={CSSbrand}>
                <img className={navbarbrandlogo}  src={CSSLogo} title="CSS"/>
            </div>
            :
            <div className={brand}>
                <img className={navbarbrandlogo} src={CarrierWhiteLogo} title="Carrier" />
            </div>
        }
        </>
    )
}
export default LogoForm;