import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import NewsignInStyles from "../signIn/newSignInFormStyle";
import LogoForm from "../brandLogo/logoForm";
import CarrierLogo from "../../../assets/images/carrier_header.png";
// import HVACLogo from "../../../assets/images/Login-HVACp.png";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia';
import ExternalSignIn from '../signIn/externalSignIn'
import CommonHeader from '../common/commonHeader'
import HvacLogo from '../../../assets/images/Hvac_Login.png'
import InternalUser  from '../../../assets/images/Carrier.ico';
import ExternalUser  from '../../../assets/images/User.svg';
import GoogleUser  from '../../../assets/images/GoogleLogo.png';

function NewSignInForm(props) {
    const { signInStatus, h2_Line,h2_span, hvacLogo,button,
        button_Hvac,button_External,cardContent_Login,span_Hvac,span_External,externalLogo
        } = NewsignInStyles();
    //const [showSignInForm, setShowSignInForm] = useState(false);
    const [showExternalSignIn, setShowExternalSignIn] = useState(false);
    const [showSignIn, setShowSignIn] = useState(true);
    const [userNameErrorFieldToDefault, setUserNameErrorFieldToDefault] = useState(false);
    const [passwordErrorFieldToDefault, setPasswordErrorFieldToDefault] = useState(false);
    const [signinErrorToDefault, setSigninErrorToDefault] = useState(false);
    const [signInStatusToDefault, setSignInStatusToDefault] = useState(false);  
    async function showSignInForm() {
        setShowSignIn(false)
        setShowExternalSignIn(!showExternalSignIn);
    }

    function onEnter(e) {
        setUserNameErrorFieldToDefault(false)
        setPasswordErrorFieldToDefault(false)
        props.signIn();
        e.preventDefault();
    }

    const closeExternalLogin=()=>{
        setShowSignIn(true)
        setShowExternalSignIn(false);
        setUserNameErrorFieldToDefault(true)
        setPasswordErrorFieldToDefault(true)
        setSigninErrorToDefault(true)
        setSignInStatusToDefault(true)
        props.onCancel()
    }

    const submitExternalLogin=()=>{
        setUserNameErrorFieldToDefault(false)
        setPasswordErrorFieldToDefault(false)
        setSigninErrorToDefault(false)
        setSignInStatusToDefault(false)
        props.signIn();
    }

    const socialLogin=()=>{
        props.SocialSignIn("Google");
    }

    return (
        <React.Fragment>
            {showSignIn &&
            <CommonHeader customTemplate={props.customTemplate} signInPage={showSignIn} header="Login">
                <div className="flex separator align-items-center">
                    <span></span>
                    <div className="lblLoginHeader">SSO & Email Login</div>
                    <span></span>
                </div>
                <form> 
                    <span className={signInStatus}>{props.signInStatus} </span>
                    <button type="button" onClick={props.CarrierSignIn} className="buttonUM">
                        <img className="btnImageCarrierSSO" src={InternalUser}/>Carrier SSO
                    </button>                    
                    <button type="button" className="buttonUM colorGreen" onClick={props.HvacSignIn}>
                        <img className="btnImage" src={HvacLogo}/>
                        <span>
                            HVAC Partners
                        </span>
                    </button>
                    <button type="button" className="buttonUM externalLogin" onClick={showSignInForm}>
                        <img className="btnImageExternal" src={ExternalUser}/>
                        <span className="spanExternal">
                            External Email
                        </span>
                    </button>
                    {/* <button type="button" className="buttonUM externalLogin" onClick={socialLogin}>
                        <img className="btnImageExternal" src={GoogleUser}/>
                        <span className="spanGoogleExternal">
                            Continue Google
                        </span>
                    </button> */}
                </form>
            </CommonHeader>
            }
            <ExternalSignIn
                showExternalSignInForm={showExternalSignIn}
                closeExternalLogin={closeExternalLogin}
                ForgotPassword={props.ForgotPassword}
                onEnter={onEnter}
                signInUnReq={props.signInUnReq}
                signInPwdReq={props.signInPwdReq}
                UserNameErrorToDefault={userNameErrorFieldToDefault}
                signInError={props.signInError} 
                signinErrorToDefault={signinErrorToDefault}
                signInStatus={props.signInStatus} 
                signInStatusToDefault={signInStatusToDefault}
                passwordErrorFieldToDefault={passwordErrorFieldToDefault}
                signIn={submitExternalLogin}
                onChange={props.onChange}
                customTemplate={props.customTemplate}>
            </ExternalSignIn>
        </React.Fragment>
    );
    
}

export default NewSignInForm;
