import { makeStyles } from "@material-ui/core/styles";
import BGImage from "../../../assets/images/BG.png";

const signUpFormStyles = makeStyles((theme) => ({
    rootsignIn: {
        height: "100%",
        width: "100%",
        "background-size": "cover",
        "background-image": `url(${BGImage})`,
        "background-repeat": "no-repeat",
        "background-attachment": "fixed",
        "background-color": "#f0f0f0",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif"
    },
    buttonstyle: {
        margin: "4px",
        width: "220px",
    },
    brand: {
        width: "30%",
        border: "none",
        "background-color": "#0033AB",
        "padding-left": "0.5rem",
        "min-width": "375px",
        "box-sizing": "border-box"
    },
    "navbarbrandlogo": {
        height: "40px",
        margin: "10px"
    },
    button: {
        "margin-bottom": "1rem",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        "color": "#003DCC",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        "background": "#FFF",
        padding: "0",
        "text-transform": "none",
        height: "32px",
        padding: "10px",
        width: "100%",
    },
    card: {
        "background-clip": "border-box",
        "background-color": "#f7f7f7",
        border: "0",
        "border-radius": "0.25rem",
        display: "flex",
        display: "-ms-flexbox",
        "-ms-flex-direction": "column",
        display: "-webkit-box",
        "-webkit-box-direction": "normal",
        "-webkit-box-orient": "vertical",
        "flex-direction": "column",
        "font-weight": "400",
        "min-width": "375px",
        /* position: relative; */
        "word-wrap": "break-word",
        /*height: "calc(100vh - 60px)",*/
        width: "30%"
    },
    cardbody: {
        flex: "1 1 auto",
        "-ms-flex": "1 1 auto",
        "-webkit-box-flex": "1",
        padding: "0.25rem",
        "text-align": "center"
    },
    logincarrierlogo: {
        "margin-bottom": "5px",
        "text-align": "center"
    },
    loginhvaclogo: {
        "margin-top": "10px",
        "text-align": "center"
    },
    externalcarriersignintext: {
        "font-size": "18px",
        color: "#000000",
        "font-weight": "bold",
        "margin-bottom": "10px",
        "margin-top": "5px"
    },
    form: {
        "margin-top": "0rem",
        "padding": "10px",
        display: "block",
        "text-align": "center",
        height: "100%"
    },
    formPanel: {
        border: "1px solid gainsboro",
        "border-radius": "3px",
        "padding": "10px 20px 5px 20px"
    },
    buttons: {
        "margin-top": "1.5rem!important"
    },
    statusMsg :{
        "margin-top": "1.5rem!important",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "11px",
    },
    formFieldLabel: {
        "color": "#000000",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "10.2px",
        "line-height": "16px",
        "text-align": "left",
        "margin-bottom": "10px",
        "margin-top": "5px",
    },
    divInput: {
        "margin-top": "-10px",
    },
    formfield: {
        width: "100%",
        "margin-bottom": "20px",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif;",
        color: "#000",
        "font-size": "12px",
        "line-height": "1em",
        padding: "5px",
        "background-color": "transparent",
        "border-bottom": "1px solid #ced4da",
        "border-radius": "0",
        "box-shadow": "none",
        color: "#000000",
        "line-height": "18px",
        "-moz-box-sizing": "border-box",
        "box-sizing": "border-box",
        "padding-left": "5px",
        "border": "1px solid #a6a6a6",
        "border-color": "#F0F0F0 #F0F0F0 #a6a6a6 #F0F0F0",
        "overflow": "visible",
        "outline": "0",
        "outline-color": "initial",
        "outline-style": "initial",
        "outline-width": "0px",
        "& .MuiInputBase-input": {
            "font-size": "13px!important",
            "color": "#000000"
        }
    },
    cancelBtn: {
        color: "#000",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        fontSize: "0.72rem",
        "&:hover": {
            "text-decoration": "underline",
            "background-color": "#0033AB !important",
            color: "#FFF!important",
            "box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "-webkit-box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "outline": 0
        }
    },
    normalBtn: {
        "background-color": "#0033AB !important",
        color: "#FFF!important",
        border: "1px solid #0033AB",
        "border-radius": "3px",
        fontSize: "0.73rem",
        "&:hover": {
            "text-decoration": "underline",
            "box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "-webkit-box-shadow": "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15)",
            "outline": 0
        }
    },

    select: {
        width: "100%",
        "background-color": "transparent",
        "border-bottom": "1px solid #ced4da",
        "color": "#000000",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "12px",
        "line-height": "18px",
        "-webkit-box-shadow": "none",
        "outline": "0",
        "box-sizing": "border-box",
        "padding-left": "5px",
        "border": "1px solid #a6a6a6",
        "border-color": "#F0F0F0 #F0F0F0 #a6a6a6 #F0F0F0",
        "margin-bottom": "20px"
    },
    terms: {
        "padding-top": "12px",
        "text-align": "center"
    },
    termsDiv: {
        display: "flex",
        "flex-direction": "row",
        "column-gap": "5px"
    },
    validError: {
        "margin-top": "-4px",
        "text-align": "left",
        color: "#f44336",
        "font-size": "12px"
    }
}));

export default signUpFormStyles;