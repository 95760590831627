import { makeStyles } from "@material-ui/core/styles";


const logoFormStyles = makeStyles((theme) => ({
    CSSbrand:{
        width: "30%",
        border: "none",
        "background-color": "#0033AB",
        "padding-left": "0.5rem",
        "min-width": "375px",
        "box-sizing": "border-box",
        textAlign:"center"
    },
}));
export default logoFormStyles;