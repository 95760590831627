import React, { useState,useEffect } from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CSSsignInStyles from "./CSSsignInFormStyle";
import signInStyles from "../signIn/signInFormStyle"
import CSSLogo from "../../../assets/images/CSS-logo.png";
import CSSFav from "../../../assets/images/CSSfavicon.ico";
import { Helmet } from "react-helmet";

function CSSSignInForm(props) {
    const { rootsignIn, navbarbrandlogo,button,externalcarriersignintext} = CSSsignInStyles();
    const { brand,card, cardbody, form,localaccount, inputLabel, formfield, validateError,reqError,     input, forgotDiv, forgotpassword, invalidUserError, signInStatus} = signInStyles();
    const [showSignInForm, setShowSignInForm] = useState(false);
    const [showInternalSignInForm, setShowInternalSignInForm] = useState(false);
    const [showExternalSignInForm, setShowExternalSignInForm] = useState(false);
    const [showBothSignInForm, setShowBothSignInForm] = useState(false);
    async function showSignIn() {
        localStorage.setItem("usertype","External");
        setShowSignInForm(!showSignInForm);
    }
    function onEnter(e) {
        props.signIn();
        e.preventDefault();
    }
    function getUrlVars()
    {
        let vars = [], hash;
        let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(let i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    } 
    useEffect(() => {
        let utype = getUrlVars()["usertype"];
        if(utype!=undefined){
            localStorage.setItem("usertype",utype);
        }
        let usertype = localStorage.getItem("usertype")
        //let usertype = "Internal";
        if(usertype == "External"){
            setShowExternalSignInForm(true)
        }
        else if(usertype == "Internal"){
            setShowInternalSignInForm(true);
        }else{
            setShowBothSignInForm(true);
        }
    },[])
    const CarrierSignIn = ()=>{
        localStorage.setItem("usertype","Internal");
        setShowBothSignInForm(false);
        props.CarrierSignIn();
    }
    return (
        <>
        <Helmet>
            <title> Cool Systems Select</title>
            <link rel="icon" href={CSSFav} type="image/x-icon" />
        </Helmet>
        
        <div className={rootsignIn}>
            <div className={brand}>
				<img className={navbarbrandlogo}  src={CSSLogo} title="CSS"/>
			</div>
            
            <div className={card}>
                <div className={cardbody}>
                    <form autoComplete="off">
                        {showInternalSignInForm &&(
                            <>
                                <div>
                                    <Button
                                        className={button}
                                        variant="contained"
                                        onClick={CarrierSignIn}
                                    >
                                        Internal Sign In
                                    </Button>
                                </div>
                            </>
                        )}
                        {showExternalSignInForm &&(
                            <>
                                <p className={externalcarriersignintext}>External Sign In</p>
                                <div>
                                    <Button
                                        className={button}
                                        variant="contained"
                                        onClick={showSignIn}
                                    >
                                        External Email Sign In
                                    </Button>
                                </div>
                            </>
                        )}
                        {showBothSignInForm &&(
                            <>
                                <div>
                                    <Button
                                        className={button}
                                        variant="contained"
                                        onClick={CarrierSignIn}
                                    >
                                       Internal Sign In
                                    </Button>
                                </div>
                                <p className={externalcarriersignintext}>External Sign In</p>
                                <div>
                                    <Button
                                        className={button}
                                        variant="contained"
                                        onClick={showSignIn}
                                    >
                                        External Email Sign In
                                    </Button>
                                </div>
                            </>
                        )}
                        {showSignInForm && (
                            <>
                                <div className={invalidUserError}>
                                    {props.signInError}
                                </div>
                                <div className={signInStatus}>
                                    {props.signInStatus}
                                </div>
                                <div className={localaccount}>
                                    <div className={formfield}>
                                        <label className={inputLabel} for="logonIdentifier">
                                            Email Address
                                        </label>
                                        <div className={validateError}>
                                            {props.signInUnReq}
                                        </div>
                                        <input
                                            name="username"
                                            type="email"
                                            id="outlined-basic"
                                            label="Email Address"
                                            onChange={props.onChange}
                                            className={`${input} ${(props.signInUnReq) ? reqError : null}`}
                                            pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    onEnter(e)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={formfield}>
                                        <label className={inputLabel} for="logonIdentifier">
                                            Password
                                        </label>
                                        <div className={validateError}>
                                            {props.signInPwdReq}
                                        </div>
                                        <input
                                            name="password"
                                            type="password"
                                            id="outlined-basic"
                                            label="Password"
                                            onChange={props.onChange}
                                            className={`${input} ${(props.signInPwdReq) ? reqError : null}`}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    onEnter(e)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={forgotDiv}>
                                        <Link className={forgotpassword}
                                            component="button"
                                            variant="body2"
                                            onClick={props.ForgotPassword}>
                                            Forgot your password?
                                        </Link>
                                    </div>
                                    <div className={formfield}>
                                        <Button
                                            className={button}
                                            variant="contained"
                                            onClick={props.signIn}>
                                            SIGN IN
                                        </Button>
                                    </div>
                                </div>

                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
        </>
    );
}

export default CSSSignInForm;
