const appConfig = {
  awsProjectRegion: "us-east-2",
 // awsCognitoIdentityPoolId: "us-east-2:6b011565-0372-4f2e-9327-53a5448cd4ae",
  awsCognitoIdentityPoolId: "us-east-2:17b95b28-8f4d-406a-b3f5-8de603de01dd",
  awsCognitoRegion: "us-east-2",
 // awsUserPoolsId: "us-east-2_0ozfAABUx",
  awsUserPoolsId: "us-east-2_5UDhEUOVM",
 // awsUserPoolsWebClientId: "3m3veigetlankmcq0n35nebs0j",
  awsUserPoolsWebClientId: "6oa1095m20vgl42bnb09dcdb3n",
 // awsCognitoDomain: "carrierappshub.auth.us-east-2.amazoncognito.com",
  awsCognitoDomain: "carrierappshub-prod.auth.us-east-2.amazoncognito.com",
  awsHVACIDP: "HVACpartnersSignIn",
  storageUri: 'https://staticfilesappshub.carrier.com/',
  api:{
    development: 'https://api.carrierappshub1.com',
  },
  ui:{
   development: 'https://www.carrierappshub1.com',
  }
};

export default appConfig;