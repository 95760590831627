import { makeStyles } from "@material-ui/core/styles";
import BGImage from "../../../assets/images/BG.png";
import backgroundImage from "../../../assets/images/backgroundImage.png";

const newForgotpasswordFormStyle = makeStyles((theme) => ({
    rootsignIn: {
        height: "100%",
        width: "100%",
        "background-size": "cover",
        "background-image": `url(${backgroundImage})`,
        "background-repeat": "no-repeat",
        "background-attachment": "fixed",
        "background-color": "#f0f0f0",
        "background-position":"bottom",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif, Roboto"
    },
    buttonstyle: {
        margin: "4px",
        width: "220px",
    },
    brand: {
        width: "30%",
        border: "none",
        "background-color": "#0033AB",
        "padding-left": "0.5rem",
        "min-width": "375px",
        "box-sizing": "border-box"
    },
    "navbarbrandlogo": {
        height: "40px",
        margin: "10px"
    },
    button: {
        "margin-bottom": "1rem",
        // border: "1px solid #0033AB",
        // "border-radius": "3px",
        "color": "#003DCC",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        // "background": "#FFF",
        //padding: "0",
        "text-transform": "none",
        //height: "32px",
        //padding: "10px",
        // width: "100%",
        // "box-shadow": "none;",
        "&:hover": {
            // "text-decoration": "underline",
            background: "#FFF;"
        },
    "padding": "13px",
    "gap": "10px",
    "width": "100%",
    "max-height": "30px",
    "background": "#FFFFFF",
    "box-shadow": "0px 0px 20px rgb(0 0 0 / 10%)",
    "border-radius": "4px",
    },
    button_SignIn: {
        "margin-bottom": "1rem",
        "color": "#FFFFF !important",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        "text-transform": "none",
        // "&:hover": {
        //     background: "#FFF;"
        // },
    "padding": "13px",
    "gap": "10px",
    "width": "100%",
    "max-height": "30px",
    // "background": "#FFFFFF",
    // "box-shadow": "0px 0px 20px rgb(0 0 0 / 10%)",
    "border-radius": "4px",
    "background": "linear-gradient(99.7deg, #15205E -19.43%, #0076F4 80.93%)",
    "box-shadow": "0px 11px 12px -10px rgb(4 105 221 / 70%)",
    "&.MuiButton-label":{
        "color": "#FFFFF !important",
    },
    },

    span_TextSignin:{
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "12px",
    "line-height": "21px",
    "text-align": "center",
    "color": "#FFFFFF",
    },

    button_Cancel: {
        "margin-bottom": "1rem",
        "color": "##000",
        "font-size": "12px",
        "line-height": "12px",
        "text-align": "center",
        "text-transform": "none",
        "&:hover": {
            background: "#FFF;"
        },
    "padding": "13px",
    "gap": "10px",
    "width": "100%",
    "max-height": "30px",
    "background": "#FFFFFF",
    "box-shadow": "0px 0px 20px rgb(0 0 0 / 10%)",
    "border-radius": "4px",
    // "background": "linear-gradient(99.7deg, #15205E -19.43%, #0076F4 80.93%)",
    // "box-shadow": "0px 11px 12px -10px rgb(4 105 221 / 70%)"
    },
    span_TextCancel:{
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "12px",
    "line-height": "21px",
    "text-align": "center",
    "color": "#00000",
    },
    card: {
        "background-clip": "border-box",
        "background-color": "#f7f7f7",
        border: "0",
        "border-radius": "0.25rem",
        display: "flex",
        // display: "-ms-flexbox",
        "-ms-flex-direction": "column",
        // display: "-webkit-box",
        "-webkit-box-direction": "normal",
        "-webkit-box-orient": "vertical",
        "flex-direction": "column",
        "font-weight": "400",
        // "min-width": "375px",
        /* position: relative; */
        "word-wrap": "break-word",
        "height": "100vh",
        "width": "0%",
        "justify-content": "center",
        "align-items": "center",
        "flex-direction": "column",
        "left": "50%",
        "position": "relative",
    },

    cardbody: {
        "min-height": "52vh",
        "width": "40vh",
       "&.MuiPaper-rounded":{
        "border-radius":"10px !important"
       },
       "&.MuiPaper-root":{
        "background-color": "#FFFFFF!important"
       }
    },

    cardbody_externalLogin: {
        "min-height": "52vh",
        "width": "50vh",
       "&.MuiPaper-rounded":{
        "border-radius":"10px !important"
       },
       "&.MuiPaper-root":{
        "background-color": "#FFFFFF!important"
       }
    },
    
    cardContentLogo:{
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "margin-top": "10px",
    },

    cardMediaImg:{
        "max-width":"50%"
    },

    divLogin:{
        // "font-family": 'Roboto',
        "font-style": "normal",
        "font-weight": "700",
        "font-size": "13px",
        "line-height": "5px",
        "display": "flex !important",
        "align-items": "flex-end",
        "color": "#152C73",
        "margin-left":"5px"
    },

    hrLeftSocialLogin:{
    "margin-top": "22px",
    "width": "63px",
    "border": "1px solid #BAC0D0",
    "margin-left": 0,
    "height": 0
    },

    h2_Line :{
        "width": "100%", 
        "text-align": "center",
        "border-bottom":" 1px solid #BAC0D0",
        "line-height": "0.2em",
        "margin": "14px 0 20px"
     } ,
     
     h2_span: { 
        // "font-family": 'Roboto';
        "font-style": "normal",
        "font-weight": "400",
        "font-size": "10.3333px",
        // "line-height": "20px",
        // "display": "flex",
        // "align-items": "flex-end",
        "color": "#848484",
        "padding":"0 10px",
        "background":"#FAFAFA"
     },

     hvacLogo:{
        "position": "relative",
        "left": "-4.83%",
        "right": "6.17%",
        "top": "9.17%",
        "bottom": "4.17%",
        "height":"15px"

     },

    //  login_id: {
    //     "width": "100% !important",
    //     "background": "#F3FAFF !important",
    //     "overflowY": "hidden !important",
    //     "border-radius":"4px",
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       "border-width": "0px !important"
    //     },
    //     "& .MuiOutlinedInput-input": {
    //       "position": "static",
    //     //   "width": " 316px",
    //       "left": "calc(50% - 316px/2)",
    //     //   "font-family": "Roboto",
    //       "font-style": "normal",
    //       "font-weight": "bold",
    //       "font-size": "14px",
    //       "line-height": "16px",
    //       "color": "#152C73 !important",
    //       "order": "1",
    //       "align-self": "stretch",
    //       "flex-grow": "0",
    //       "margin": "2px 0px 2px 5px"
    //     },
    //     "& .MuiInputLabel-root": {
    //       "top": "6px !important",
    //       "color": "#152C73 !important",
    //       "line-height": "12px !important",
    //     //   "font-family": "Roboto !important",
    //       "font-style": "normal !important",
    //       "font-weight": "normal !important",
    //       "font-size": "12px !important",
    //       "left":"-9px"
    //     },
    //     "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense":{
    //       "padding-left":"0px"
    //     }
        
    //   },

    //   email_Id: {
    //     "width": "100% !important",
    //     "background": "#F3FAFF !important",
    //     "overflowY": "hidden !important",
    //     "border-radius":"4px",
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       "border-width": "0px !important"
    //     },
    //     "& .MuiOutlinedInput-input": {
    //       "position": "static",
    //       "left": "calc(50% - 316px/2)",
    //       "font-style": "normal",
    //       "font-weight": "bold",
    //       "font-size": "14px",
    //       "line-height": "16px",
    //       "color": "#152C73 !important",
    //       "order": "1",
    //       "align-self": "stretch",
    //       "flex-grow": "0",
    //       "margin": "2px 0px 2px 0px",
    //       "padding": "9.5px 6px !important"
    //     },
    //     "& .MuiInputLabel-root": {
    //       "top": "6px !important",
    //       "color": "#152C73 !important",
    //       "line-height": "12px !important",
    //       "font-style": "normal !important",
    //       "font-weight": "normal !important",
    //       "font-size": "10px !important",
    //       "left":"-9px"
    //     },
    //     "& .MuiOutlinedInput-root": {
    //       padding:"0px"
    //     }
    //   },
    signupComponent: {
      "width": "100% !important",
      "background": "#F3FAFF !important",
      "overflowY": "hidden !important",
      "overflow-x": "hidden !important",
      "border-radius":"4px",
      "& .MuiOutlinedInput-notchedOutline": {
        "border-width": "0px !important"
      },
      "& .MuiOutlinedInput-input": {
        "position": "static",
        "left": "calc(50% - 316px/2)",
        "font-style": "normal !important",
        "font-weight": "bold !important",
        "font-size": "14px !important",
        "line-height": "16px !important",
        "color": "#152C73 !important",
        //"color":"inherit",
        "order": "1",
        "align-self": "stretch",
        "flex-grow": "0",
        "margin": "2px 0px 2px 0px",
        "padding": "9.5px 6px !important"
      },
      "& .MuiInputLabel-root": {
      //   "top": "6px !important",
        "color": "#152C73 !important",
        //"color":"inherit",
        "line-height": "12px !important",
        "font-style": "normal !important",
        "font-weight": "normal !important",
        "font-size": "12px !important",
        "left":"-9px"
      },
      "& .MuiOutlinedInput-root": {
        padding:"0px"
      },
      "& .MuiFilledInput-root":{
          "background-color":"#F3FAFF !important"
      },
      "& .MuiFilledInput-root .Mui-focused":{
          "background-color":"none !important"
      },
      "& .MuiFilledInput-underline:before":{
          "border-bottom":"none !important"
      },
      "& .MuiFilledInput-underline:after":{
          "border-bottom":"none !important"
      },
      "& .MuiFilledInput-input": {
          "padding-left": "4px",
          "font-style": "normal !important",
        "font-weight": "bold !important",
        "font-size": "14px !important",
        "line-height": "16px !important",
        "color": "#152C73 !important",
      },
      "& .MuiInputBase-input":{
        "height":"1em !important"
      }
    },
      cardContent_email:{
        "&.MuiCardContent-root":{
          "padding-left":"0px !important",
          "padding-right":"0px !important"
        }
      },

      cardContent_Button:{
        "&.MuiCardContent-root":{
        "padding-top":"0px !important",
        "padding-left":"0px !important",
        "padding-right":"0px !important",
        "padding-bottom": "0px !important",
        },
        // "&.MuiCardContent-root:last-child": {
        //     "padding-bottom": "5px",
        // }
      },

      validError: {
        "margin-top": "2px",
        "text-align": "left",
        color: "#f44336",
        "font-size": "12px"
    },

      div_Cancel:{
        "width": "100%",
        "display": "flex",
        // "justify-content": "left",
      },

      div_SignIn:{
        "width": "100%",
        "display": "flex",
        // "justify-content": "right",
      },


    logincarrierlogo: {
        "margin-bottom": "5px",
        "text-align": "center"
    },
    loginhvaclogo: {
        "margin-top": "10px",
        "text-align": "center"
    },
    externalcarriersignintext: {
        "font-size": "18px",
        color: "#000000",
        "font-weight": "bold",
        "margin-bottom": "10px",
        "margin-top": "5px",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif;"
    },
    localaccount: {
        "margin-top": "0rem",
        "padding": "10px",
        display: "block",
        "text-align": "center",
        border: "1px solid gainsboro",
        "border-radius": "4px"
    },
    formfield: {
        position: "relative",
        "text-align": "left",
        "line-height": "1.5",
        color: "#212529",
        "box-sizing": "border-box",
        // display: "flex",
        "flex-direction": "row",
        "margin-top": "0.9rem"
    },
    input: {
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif;",
        color: "#000",
        "font-size": "12px",
        "line-height": "1em",
        padding: "5px",
        "background-color": "transparent",
        "border-bottom": "1px solid #ced4da",
        "border-radius": "0",
        "box-shadow": "none",
        color: "#000000",
        "line-height": "18px",
        "-moz-box-sizing": "border-box",
        "box-sizing": "border-box",
        "padding-left": "5px",
        "border": "1px solid #a6a6a6",
        "border-color": "#F0F0F0 #F0F0F0 #a6a6a6 #F0F0F0",
        "overflow": "visible",
        "outline": "0",
        "outline-color": "initial",
        "outline-style": "initial",
        "outline-width": "0px"
    },
    reqError: {
        "border-bottom": "1px solid #f44336",
        "box-shadow": "0 1px 0 0 #f44336",
        "-webkit-box-shadow": "0 1px 0 0 #f44336"
    },
    inputLabel: {
        color: "#000000",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "10px",
        "line-height": "16px",
        "text-align": "left",
        "margin-bottom": "0.5rem",
        "box-sizing": "border-box",
        "font-weight": "400",
        "word-wrap": "break-word"
    },
    forgotDiv: {
        position: "relative",
        "margin-bottom": "30px",
         "margin-top": "5px"
    },
    forgotpassword: {
        color: "#1891F6;",
        // "text-decoration": "underline",
        cursor: "pointer",
        display: "block",
        height: "18px",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
        "font-size": "12px",
        "line-height": "18px",
        "margin-top": "0px",
        "margin-right": "0px",
        float: "right"
    },
    eulaerror: {
        "text-align": "left",
        color: "#f44336"
    },
    validateError: {
        "text-align": "left",
        color: "#f44336",
        "font-size": "10px",
        "margin-left": "5px"
    },
    invalidUserError: {
        "margin-top": "5px",
        "margin-bottom": "12px",
        "text-align": "center",
        "color": "#f44336",
        "font-size": "0.8rem",
        "font-family": "Helvetica Neue, Helvetica, Arial, sans - serif"
    },
    signInStatus: {
      "margin-top": "0px",
      "margin-bottom": "12px",
      "text-align": "left",
      "font-size": "10px",
      "margin-left":"5px",
      "color":"gray"
    },
    signInStatusRed: {
      "margin-top": "0px",
      "margin-bottom": "12px",
      "text-align": "left",
      "font-size": "10px",
      "margin-left":"5px",
      "color":"red"
    },
    inputError: {
        width: "95%",
        "border-bottom": "1px solid #f44336"
    }
    
}));

export default newForgotpasswordFormStyle;