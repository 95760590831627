import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { countryList } from "../../../constant/constant";
import internalSignUpFormStyles from "./internalSignUpFormStyle";
import LogoForm from "../brandLogo/logoForm";
import { clearUMCache } from '../../../js/utilities';

function InternalSignUpForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form, eulaerror, select,
        formfield, cancelBtn, normalBtn, formPanel, formFieldLabel, buttons, divInput} = internalSignUpFormStyles();
    const [contryError, setContryError] = useState("");

    function signUp() {
        const { 'custom:country': country } = props.formState;
        const verified = verification(country);
        if (verified) {
            props.internalSignUp();
        }
    }

    function cancel() {
        localStorage.clear();
        //clearUMCache();
        //console.log("InternalSignUpcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    function verification(country) {
        let isVerified = true;
        if (country == null || country == "") {
            setContryError("Country/Region is required");
            isVerified = false;
        }
        else {
            setContryError("");
        }

        return isVerified;
    }

    function countryNamechange(e) {
        if (e.target.value == null || e.target.value == "") {
            setContryError("Country/Region is required");
        }
        else {
            setContryError("");
        }
        props.onChange(e);
    }

    return (
        <div className={rootsignIn}>
            <LogoForm appConfig={props.appConfig}/>
            <div className={card}>
                <div className={cardbody}>
                    <form noValidate autoComplete="off" className={form}>
                        <div className={formPanel} >
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    First Name
                                </div>
                                <input
                                    disabled
                                    value={props.formState.given_name}
                                    name="given_name"
                                    id="outlined-basic"
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Last Name
                                </div>
                                <input
                                    disabled
                                    value={props.formState.family_name}
                                    name="family_name"
                                    id="outlined-basic"
                                    className={formfield}
                                />
                            </div>
                            <div className={divInput}>
                                <div className={formFieldLabel}>
                                    Country/Region
                                </div>
                                <div className={eulaerror}>
                                    {contryError}
                                </div>
                                <select name="custom:country" onChange={countryNamechange} className={select}>
                                    <option value="">Country/Region</option>{
                                        countryList.map((obj) => {
                                            return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={buttons}>
                            <Button className={`${formfield} ${normalBtn}`} onClick={signUp}>
                                Continue
                            </Button>
                            <Button className={`${formfield} ${cancelBtn}`} onClick={cancel}>
                                CANCEL
                            </Button>
                        </div>
                        <div>
                            {props.internalSignUpStatus}
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default InternalSignUpForm;
