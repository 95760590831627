import React, { useState, useEffect } from "react";
import forgotPasswordFormStyles from "./forgotPasswordFormStyle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LogoForm from "../brandLogo/logoForm";


function IsEmailValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = false;
    if (re.test(email)) {
        isValid = true;
    }
    return isValid;
}

function ForgotPasswordForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form, validateError, userError,
        formfield, cancelBtn, normalBtn, formPanel, formFieldLabel, buttons} = forgotPasswordFormStyles();
    const [emailAddressError, setEmailAddressError] = useState("");
    function emailChange(e) {
        if (e.target.value == null || e.target.value == undefined || e.target.value.trim() == "") {
            setEmailAddressError("Please enter your email");
        }
        else if (!IsEmailValid(e.target.value.trim())) {
            setEmailAddressError("Please enter a valid email address");
        }
        else {
            setEmailAddressError("");
        }

        props.onChange(e);
    }

    function forgotPassword() {
        const { email } = props.formState;
        const verified = verification(email);
        if (verified) {
            props.NotificationForgotPassword();
        }
    }

    function cancel() {
        //console.log("ForgotPasswordcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri;
    }

    function verification(username) {
        let isVerified = true;
        if (username == null || username == undefined || username.trim() == "") {
            setEmailAddressError("Please enter your email");
            isVerified = false;
        } else if (!IsEmailValid(username.trim())) {
            setEmailAddressError("Please enter a valid email address");
            isVerified = false;
        } else {
            setEmailAddressError("");
        }

        return isVerified;
    }

    function onEnter(e) {
        forgotPassword();
        e.preventDefault();
        e.stopPropagation();
        return true;
    }

    return (
        <div className={rootsignIn}>
            <LogoForm appConfig={props.appConfig}/>
            <div className={card}>
                <div className={cardbody}>
                    <div className={`${validateError} ${userError}`}>
                        {props.forgotPassword}
                    </div>
                    <form className={form} onSubmit={(e) => onEnter(e)}>
                        <div className={formPanel} >
                            <div className={formFieldLabel}>
                                Email Address
                            </div>
                            <div className={validateError}>
                                {emailAddressError}
                            </div>
                            <input
                                name="email"
                                type="email"
                                pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                id="outlined-basic"
                                onChange={emailChange}
                                className={formfield}
                            // onKeyPress={(e) => {
                            //     if (e.key === 'Enter') {
                            //         onEnter(e)
                            //     }
                            // }}
                            />
                        </div>
                        <div className={buttons}>
                            <Button className={`${formfield} ${normalBtn}`} onClick={forgotPassword}>
                                SEND VERIFICATION EMAIL
                            </Button>
                            <Button className={`${formfield} ${cancelBtn}`} onClick={cancel}>
                                CANCEL
                            </Button>
                        </div>
                        <div>
                            {props.forgotPwdStatus}
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}

export default ForgotPasswordForm;
