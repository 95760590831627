import React, {useState, useEffect} from "react";
import './commonHeader.css';

function CommonHeader(props) {
    return (
        <div className="mainDiv" style={props.customTemplate.bgImage} >
            <h1 id="outerLogo" title="CarrierAppsHub" className="flex justify-content-center">
                 <img src={props.customTemplate.appImage}>
                </img>
            </h1>
            <div className="childContainer flex">
                <div id="innerChild" className="flex direction-column">
                    <div className=" login flex direction-column">
                        <h1 id="innerLogo" title="CarrierAppsHub" className="flex justify-content-center">
                            <img src={props.customTemplate.appImage}>
                            </img>
                        </h1>          
                        <div className="flex direction-column">
                            <div className="lblLogin" style={props.customTemplate.fontColor}>
                                {props.header}
                            </div>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
	    </div>
    );
}

export default CommonHeader;