import React, { useState, useEffect } from "react";
import forgotPasswordFormStyles from "./forgotPasswordFormStyle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LogoForm from "../brandLogo/logoForm";
import NewsignInStyles from "../signIn/newSignInFormStyle";
import NewForgotpasswordFormStyle from "./newForgotpasswordFormStyle";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CarrierLogo from "../../../assets/images/carrier_header.png";
import CommonHeader from '../common/commonHeader'


function IsEmailValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = false;
    if (re.test(email)) {
        isValid = true;
    }
    return isValid;
}

function ForgotPasswordForm(props) {
    const {email_Id,password
        ,validateError,formfield,cardContent_Button,button_SignIn,div_Cancel,div_SignIn,button_Cancel,span_TextSignin,span_TextCancel,cardContent_email,signInStatus,signInStatusRed,signupComponent} = NewForgotpasswordFormStyle();
    const [emailAddressError, setEmailAddressError] = useState("");
    
    function emailChange(e) {
        if (e.target.value == null || e.target.value == undefined || e.target.value.trim() == "") {
            setEmailAddressError("Please enter your email");
        }
        else if (!IsEmailValid(e.target.value.trim())) {
            setEmailAddressError("Please enter a valid email address");
        }
        else {
            setEmailAddressError("");
        }
        //console.log(e)
        props.onChange(e);
    }

    function forgotPassword() {
        const { email } = props.formState;
        const verified = verification(email);
        if (verified) {
            props.NotificationForgotPassword();
        }
    }

    function cancel() {
        //console.log("ForgotPasswordcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri;
    }

    function verification(username) {
        let isVerified = true;
        if (username == null || username == undefined || username.trim() == "") {
            setEmailAddressError("Please enter your email");
            isVerified = false;
        } else if (!IsEmailValid(username.trim())) {
            setEmailAddressError("Please enter a valid email address");
            isVerified = false;
        } else {
            setEmailAddressError("");
        }

        return isVerified;
    }

    function onEnter(e) {
        forgotPassword();
        e.preventDefault();
        e.stopPropagation();
        return true;
    }
    
    return (
        <div>
            <CommonHeader header="Forgot Password" customTemplate={props.customTemplate}>
                {(emailAddressError && 
                    <div className={validateError}>
                        {emailAddressError}
                    </div>
                )}
                {(props.forgotPwdStatus && 
                    <div className={props.forgotPwdStatus.indexOf("email") !== -1? ` ${signInStatusRed}`: ` ${signInStatus}`}>
                        {props.forgotPwdStatus}
                    </div>
                )}
                <div onSubmit={(e) => onEnter(e)}>
                    <TextField
                        name="email"
                        className={signupComponent}
                        autoComplete='off'
                        label="email"
                        variant="filled"
                        size="small"
                        pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                        autoFocus
                        onChange={emailChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                forgotPassword();
                            }
                        }}
                    />
                </div>                
                <div className={formfield}>
                    <div className={div_SignIn}>
                        <Button
                            className="btnExtSignIn"
                            variant="contained"
                            onClick={forgotPassword}>
                            <span className={span_TextSignin}>SEND VERIFICATION EMAIL</span>
                        </Button>
                    </div> 
                </div>
                <div className={formfield}>
                    <div className={div_Cancel}>
                        <Button
                            className="buttonUM btnCancel"
                            // variant="contained"
                            onClick={props.cancel}>
                            <span className={span_TextCancel}>Cancel</span>  
                        </Button>
                    </div>
                        
                </div>
            </CommonHeader>
        </div>
    )
}

export default ForgotPasswordForm;
