import React, { useState } from "react";
import newSignUpFormStyles from "./newSignUpFormStyle";
import TextField from "@material-ui/core/TextField";
import CommonHeader from '../common/commonHeader';
import { countryList } from "../../../constant/constant";
import { GetCountryStates, GetStateCities } from '../../../js/utilities';

function NewSocialSignUpForm(props) {
    const { select, formfield, formFieldLabel, validError, divInput, statusMsg, failStatus,
        signupComponent, div_Cancel, div_SignIn, div_Control_TopRrow_Display, childContainer_div,
        divDdlWidth, div_RightComponent_Separator, div_ButtonMargin, div_LeftComponent_Separator } = newSignUpFormStyles();

    const [contryError, setContryError] = useState("");
    const [stateError, setStateError] = useState("");
    const [cityError, setCityError] = useState("");
    const [countryName, setCountryName] = useState("");
    const [countryStatus, setCountryStatus] = useState(false);
    const [stateStatus, setStateStatus] = useState(false);
    const [stateListObj, setStateListObj] = useState([{"name": "--Select a value--"}]);
    const [cityListObj, setCityListObj] = useState(["--Select a value--"]);

    const GetCountryState =  async(country) => {
        try{
            setStateListObj([{"name": "Loading..."}]);
            props.formState["custom:state"] = "";
            props.formState["custom:city"] = "";
            setCountryStatus(true);            
            await GetCountryStates({"country": country}, function(response) {
                setStateListObj([{"name": "--Select a value--"}].concat(response.data.states));
                setCountryStatus(false);
                //setStateName(response.data.states[0].name);
                if(response.data.states[0].name != "Other"){
                    setCountryName(country);
                    //GetStateCity(response.data.states[0].name, country);
                }
            });
        }catch(error){
            console.log(error);
        }
    };

    const GetStateCity =  async(state, country) => {
        setCityListObj(["Loading..."]);
        props.formState["custom:city"] = "";
        setCountryStatus(true);
        setStateStatus(true);
        await GetStateCities({"country": (country == "" || typeof country == "undefined")? countryName : country, "state": state}, function(response) {           
            setCityListObj(["--Select a value--"].concat(response.data));
            setCountryStatus(false);
            setStateStatus(false);
            //setCityName(response.data[1]);
        });
    };

    async function signUp() {
        const { username, given_name, family_name, 'custom:country': country , 'custom:state': state , 'custom:city': city } = props.formState;
        const verified = verification(country, state, city);

        if (verified) {
            await props.signUp();
        }
    }

    function cancel() {
        localStorage.clear();
        window.location.href = props.appConfig.postLogOutUri;
    }

    function verification(country, state, city) {
        let isVerified = true;

        if (country == null || country == "") {
            setContryError("Country is required");
            isVerified = false;
        } else {
            setContryError("");
        }

        if (state == null || state == "" || state == "--Select a value--") {
            setStateError("State/Region is required");
            isVerified = false;
        } else {
            setStateError("");
        }

        if (city == null || city == "" || city == "--Select a value--") {
            setCityError("City is required");
            isVerified = false;
        } else {
            setCityError("");
        }

        return isVerified;
    }

    function countryNamechange(e) {
        setStateListObj([{"name": "--Select a value--"}]);
        setCityListObj(["--Select a value--"]);

        if (e.target.value == null || e.target.value == "") {
            setContryError("Country is required");
        } else {
            setCountryName(e.target.value);
            GetCountryState(e.target.value);
            setContryError("");
        }
        props.onChange(e);
    }

    function stateNamechange(e) {
        if (e.target.value == null || e.target.value == "" || e.target.value == "--Select a value--") {
            setStateError("State/Region is required");
        } else {
            //setStateName(e.target.value);
            GetStateCity(e.target.value);
            setStateError("");
        }

        props.onChange(e);
    }

    function cityNamechange(e) {
        if (e.target.value == null || e.target.value == "" || e.target.value == "---Select a value--") {
            setCityError("State is required");
        }
        else {
            //setCityName(e.target.value);
            setCityError("");
        }

        props.onChange(e);
    }

    return (
        <React.Fragment>
            <div className={childContainer_div}>
            <CommonHeader header="SignUp" customTemplate={props.customTemplate}>
                <span className={props.internalSignUpStatus.indexOf("already") !== -1? `${statusMsg} ${failStatus}`: `${statusMsg}`}>
                    {props.internalSignUpStatus}
                </span>
                <div>
                    <div className={div_Control_TopRrow_Display}>
                        <TextField
                            name="username"
                            className={signupComponent}
                            id="umEmail"
                            label="email"
                            variant="filled"
                            size="small"
                            pattern="^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                            autoFocus
                            disabled
                            defaultValue={props.userEmail}
                        />
                        <div className={div_RightComponent_Separator}>
                            <div className={validError}>
                                {contryError}
                            </div>
                            <div className={divDdlWidth}>
                                <div className={formFieldLabel}>
                                    country
                                </div>
                                <select name="custom:country" onChange={countryNamechange} className={select} disabled={countryStatus}>
                                    <option value="">--Select a value--</option>{
                                        countryList.map((obj) => {
                                            return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>                       
                    </div>              
                    <div className={divInput}>
                        <div className={div_LeftComponent_Separator}>
                            <div className={validError}>
                                {stateError}
                            </div>
                            <div className={divDdlWidth}>
                            <div className={formFieldLabel}>
                                state/region
                            </div>
                            <select name="custom:state" onChange={stateNamechange} className={select} disabled={stateStatus}>
                                {
                                    stateListObj.map((obj) => {
                                        return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                    })
                                }
                            </select>
                            </div>
                        </div>                    
                        <div className={div_RightComponent_Separator}>
                            <div className={validError}>
                                {cityError}
                            </div>
                            <div className={divDdlWidth}>
                            <div className={formFieldLabel}>
                                city
                            </div>
                            <select name="custom:city" onChange={cityNamechange} className={select}>  
                                {
                                    cityListObj.map((obj) => {
                                        return <option key={obj} value={obj}>{obj}</option>
                                    })
                                }     
                            </select>
                        </div>
                        </div>
                    </div>
                </div>
                <div className={div_ButtonMargin}>                
                    <div className={formfield}>
                        <div className={div_SignIn}>
                            <button disabled={props.internalSignUpStatus.indexOf("wait") !== -1? `disabled`: ``} type="button" className="btnExtSignIn" onClick={signUp}>
                                <span className="btnLabel">
                                    Continue
                                </span>
                            </button>
                        </div> 
                        <div className={div_Cancel}>
                            <button type="button" className="buttonUM btnCancel" onClick={cancel}>
                                <span className="btnLabel">
                                    Cancel
                                </span>
                            </button>
                        </div>      
                    </div> 
                </div>                          
             </CommonHeader>
            </div>           
        </React.Fragment>
    );
}

export default NewSocialSignUpForm;