import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ResertPasswordFormStyles from "./newResetPasswordFormStyle";
import CommonHeader from '../common/commonHeader'
import HelpImage  from '../../../assets/images/HelpImage.png';

function ResetPasswordForm(props) {
    const { login_id,password,formfield,button_SignIn,div_Cancel,div_SignIn,button_Cancel,
        span_TextSignin,span_TextCancel,signupComponent, progressStatus, validError} = ResertPasswordFormStyles();
    const [passwordError, setPasswordError] = useState("");
    const [isPasswordErrorForPassType, setIsPasswordErrorForPassType] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    function resetPassword() {
        const { password, confirmPassword } = props.formState;
        const verified = verification(password, confirmPassword);
        if (verified) {
            props.confirmPassword();
        }
    }

    function cancel() {
        //console.log("ResetPasswordcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    function verification(password, confirmPassword) {
        let isVerified = true;
        var regExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,64}$/);
        if (password == null || password == "") {
            setPasswordError("Password is required");
            isVerified = false;
        }else if(regExp.test(password) == false){
            setPasswordError(`Password must be between 8 and 64 characters.
            The password must have following:
            <div>&nbsp; -a lowercase letter</div>
            <div>&nbsp; -a uppercase letter</div>
            <div>&nbsp; -a digit</div>
            &nbsp;-a symbol`);
            isVerified = false;
            setIsPasswordErrorForPassType(true)
        } else {
            setPasswordError("");
            setIsPasswordErrorForPassType(false)
        }

        if (confirmPassword == null || confirmPassword == "") {
            setConfirmPasswordError("Confirm Password is required");
            isVerified = false;
        }
        else if (password != "" && confirmPassword != "" && password != confirmPassword) {
            setConfirmPasswordError("Password & Confirm Password must be same");
            isVerified = false;
        }
        else {
            setConfirmPasswordError("");
        }

        return isVerified;
    }

    function passwordchange(e) {
        var regix = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,64}$/);
        if (e.target.value == null || e.target.value == "") {
            setPasswordError("Password is required");
        } else if (regix.test(e.target.value) == false) {
            setPasswordError(
            `Password must be between 8 and 64 characters.
            The password must have following:
             -a lowercase letter
             -a uppercase letter
             -a digit
             -a symbol`);
            setIsPasswordErrorForPassType(true)
        } else {
            setPasswordError("");
            setIsPasswordErrorForPassType(false)
        }
        props.onChange(e);
    }

    function confirmPasswordchange(e) {
        if (e.target.value == null || e.target.value == "") {
            setConfirmPasswordError("Confirm Password is required");
        }
        else {
            setConfirmPasswordError("");
        }
        props.onChange(e);
    }

    return (
        <div>
            <CommonHeader header="Reset Password" customTemplate={props.customTemplate}>
               {(props.resetPwdStatus)? 
                  <div className={props.resetPwdStatus.indexOf("Error") !== -1? ` ${validError}`: ` ${validError} ${progressStatus}`}>
                    {props.resetPwdStatus}
                  </div>
                : <div className={validError}>
                    {(
                        isPasswordErrorForPassType===true ?<div><span>Password does not meet the criteria</span><img 
                        src={HelpImage} title={passwordError}></img><span></span></div>:passwordError
                    )}
                  </div>
                }
               <div>
                  <TextField
                    name="password"
                    className={signupComponent}
                    id="resetPassword"
                    label="password"
                    type="password"
                    variant="filled"
                    size="small"
                    autoComplete='off'
                    onChange={passwordchange}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            resetPassword();
                        }
                    }}
                    //InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div  style={{"margin-top":"16px"}}>
                    {(
                        confirmPasswordError!= ""? <span className={validError}>
                        {confirmPasswordError}
                    </span>:<></>
                    )}
                    <TextField
                        name="confirmPassword"
                        className={signupComponent}
                        id="confirmPassword"
                        label="confirm password"
                        type="password"
                        variant="filled"
                        size="small"
                        autoComplete='off'
                        onChange={confirmPasswordchange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                resetPassword();
                            }
                        }}
                    />
                </div>  
                <div className={formfield}>              
                <div className={div_SignIn}>
                    <Button
                         className="btnExtSignIn"
                        variant="contained"
                        onClick={resetPassword}>
                        <span className={span_TextSignin}>Continue</span>
                    </Button>
                </div>
                <div className={formfield}> 
                <div className={div_Cancel}>
                    <Button
                          className="buttonUM btnCancel"
                        // variant="contained"
                        onClick={cancel}>
                        <span className={span_TextCancel}>Cancel</span>
                    </Button>
                </div>
                </div>
                </div>
            </CommonHeader>
        </div>
    );
}

export default ResetPasswordForm;
