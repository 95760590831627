import React, { useState } from "react";
import newHvacSignUpFormStyles from "./newHvacSignUpFormStyle";
import TextField from "@material-ui/core/TextField";
import CommonHeader from '../common/commonHeader';

function NewHvacSignUpForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form, select, formfield, cancelBtn,
        normalBtn, formPanel, formFieldLabel, buttons, validError, divInput, statusMsg,loginId,signupComponent,signUpComponentScroll,div_Cancel,div_SignIn} = newHvacSignUpFormStyles();

    function signUp() {
        props.hvacSignUp();
    }

   function cancel() {
        localStorage.clear();
        //clearUMCache();
        //console.log("InternalSignUpcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    return (
        <React.Fragment>
             <CommonHeader header="HVAC SignUp" customTemplate={props.customTemplate}>
             <span className={statusMsg}>
                            {props.internalSignUpStatus}
            </span>
                <div className={signUpComponentScroll}>
                <div>
                    <TextField
                        name="username"
                        className={signupComponent}
                        id="umFirstEmail"
                        label="username"
                        variant="filled"
                        size="small"
                        autoComplete='off'
                        disabled
                        defaultValue={props.formState.username && props.formState.username.toUpperCase()}
                    />
                </div>
                </div>
                <div className={formfield}>
                <div className={div_SignIn}>
                        <button type="button" className="btnExtSignIn" onClick={signUp}>
                            <span className="btnLabel">
                            Continue
                            </span>
                        </button>
                    </div>
                    <div className={div_Cancel}>
                        <button type="button" className="buttonUM btnCancel" onClick={cancel}>
                            <span className="btnLabel">
                                Cancel
                            </span>
                        </button>
                    </div>       
                </div>
            
             </CommonHeader>

        </React.Fragment>
    );

}
export default NewHvacSignUpForm;