import React, { useState } from "react";
import newInternalSignUpFormStyles from "./newInternalSignupFormStyle";
import TextField from "@material-ui/core/TextField";
import CommonHeader from '../common/commonHeader';
import { countryList } from "../../../constant/constant";

function NewInternalSignUpForm(props) {
    const { rootsignIn, brand, navbarbrandlogo, card, cardbody, form, select, formfield, cancelBtn,
        normalBtn, formPanel, formFieldLabel, buttons, validError, divInput, statusMsg,loginId,signupComponent,signUpComponentScroll,div_Cancel,div_SignIn} = newInternalSignUpFormStyles();

        const [contryError, setContryError] = useState("");

    function signUp() {
        const { 'custom:country': country } = props.formState;
        const verified = verification(country);
        if (verified) {
            props.internalSignUp();
        }
    }

   function cancel() {
        localStorage.clear();
        //clearUMCache();
        //console.log("InternalSignUpcancel ----> ", props.appConfig.postLogOutUri)
        window.location.href = props.appConfig.postLogOutUri
    }

    function verification(country) {
        let isVerified = true;
        if (country == null || country == "") {
            setContryError("Country/Region is required");
            isVerified = false;
        }
        else {
            setContryError("");
        }

        return isVerified;
    }

    function countryNamechange(e) {
        if (e.target.value == null || e.target.value == "") {
            setContryError("Country/Region is required");
        }
        else {
            setContryError("");
        }
        props.onChange(e);
    }


    return (
        <React.Fragment>
             <CommonHeader header="SSO SignUp" customTemplate={props.customTemplate}>
             <span className={statusMsg}>
                            {props.internalSignUpStatus}
            </span>
                <div className={signUpComponentScroll}>
                <div>
                    <TextField
                        name="given_name"
                        className={signupComponent}
                        id="umFirstEmail"
                        label="first name"
                        variant="filled"
                        size="small"
                        autoComplete='off'
                        disabled
                        defaultValue={props.formState.given_name}
                    />
                </div>
                <div style={{"margin-top":"16px"}}>
                    <TextField
                        name="family_name"
                        className={signupComponent}
                        id="umLastName"
                        label="last name"
                        variant="filled"
                        size="small"
                        autoComplete='off'
                        disabled
                        defaultValue={props.formState.family_name}
                    />
                </div>
                <div style={{"margin-top":"16px"}} className={divInput}>
                    {(
                        contryError!= ""? <span className={validError}>
                        {contryError}
                    </span>:<></>
                    )}
              
                                <div className={formFieldLabel}>
                                    country
                                </div>
                                <select name="custom:country" onChange={countryNamechange} className={select} >
                                    <option value="">--Select a value--</option>{
                                        countryList.map((obj) => {
                                            return <option key={obj.name} value={obj.name}>{obj.name}</option>
                                        })
                                    }
                                </select>
                </div>
                </div>
                <div className={formfield}>
                <div className={div_SignIn}>
                        <button type="button" className="btnExtSignIn" onClick={signUp}>
                            <span className="btnLabel">
                            Continue
                            </span>
                        </button>
                    </div>  
                    <div className={div_Cancel}>
                        <button type="button" className="buttonUM btnCancel" onClick={cancel}>
                            <span className="btnLabel">
                                Cancel
                            </span>
                        </button>
                    </div>    
                </div>
            
             </CommonHeader>

        </React.Fragment>
    );

}
export default NewInternalSignUpForm;